:root {
	--defaultFontSize: 16px;
	--defaultFontWeight: 400;
	--defaultLineHeight: 1.5rem;
}

/* @include textLineClamp(2, --defaultLineHeight, fixed); */

@mixin textLineClamp($line: 1, $lineHeight: --defaultLineHeight, $type: fixed) {
	@if $line == 1 {
		display: block;
		overflow: hidden;
		width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-break: break-all;
	} @else  {
		display: -webkit-box;
		overflow: hidden;
		width: 100%;
		text-overflow: ellipsis;
		white-space: normal;
		word-break: break-all;
		-webkit-line-clamp: $line;
		-webkit-box-orient: vertical;
	}

	@if $type == fixed {
		@if $lineHeight == --defaultLineHeight {
			min-height: calc(var($lineHeight) * $line);
			max-height: calc(var($lineHeight) * $line);
		} @else  {
			min-height: calc($lineHeight * $line);
			max-height: calc($lineHeight * $line);
		}
	} @else  {
		@if $lineHeight == --defaultLineHeight {
			min-height: var($lineHeight);
			max-height: calc(var($lineHeight) * $line);
		} @else  {
			min-height: $lineHeight;
			max-height: calc($lineHeight * $line);
		}
	}

	@if $lineHeight == --defaultLineHeight {
		line-height: var($lineHeight);
	} @else  {
		line-height: $lineHeight;
	}
}

/* @include horizonScroll(15px, 12px) */

@mixin horizonScroll($padding: --defaultFontSize, $spacing: --defaultFontSize, $element: li) {
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	-webkit-overflow-scrolling: auto;
	padding-left: $padding;

	&::after {
		content: "";
		display: flex;
		flex: none;
		width: $padding;
	}

	& > $element {
		flex: none;

		& + $element {
			margin-left: $spacing;
		}
	}
}
