.mobile {
	& #resign {
		& > ul {
			& > li {
				& > button {
					&.selected {
						&::before,
						&::after {
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 86 30'%0A%3E%3Cg fill='none' fill-rule='evenodd' stroke-opacity='.05' %3E%3Cg fill='%23FF422E' stroke='%23000' stroke-width='1.075' %3E%3Cg%3E%3Cpath d='M71.562.536c.953-.034 1.92.29 2.677.985h0l9.7 10.033c.82.848 1.238 1.942 1.253 3.042.014 1.099-.375 2.204-1.172 3.073h0l-9.69 10.574c-.747.815-1.771 1.226-2.8 1.22h0H5c-1.232 0-2.348-.5-3.155-1.308C1.037 27.348.538 26.232.538 25h0V5c0-1.232.499-2.347 1.307-3.155C2.652 1.038 3.768.538 5 .538h0z' transform='translate(-453.000000, -214.000000) translate(453.000000, 214.000000)' /%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
						}
					}
				}
			}
		}

		& .btn {
			&-btmfixed {
				@apply bottom-0;
				bottom: calc(theme("spacing.0") + env(safe-area-inset-bottom));
			}
		}
	}
}
