@import "@dg/common/styles/common/function.css";

@import "../mobile/mobile.css";

#layerpopup {
	&-address {
		@apply pb-0 pt-1;

		& .layerpopup {
			&-header {
				& strong {
					@apply justify-start;
				}
			}
		}
	}

	&-googlemap {
		& .layerpopup {
			&-content {
				@apply p-0;
			}
		}
	}
}
