.mobile {
	& #myinfo {
		&.edit {
			& div[id="tooltip-phone"] {
				& > div {
					&:first-child {
						@apply border-b-0;

						& strong {
							@apply justify-center pt-8 text-lg;
						}

						& button {
							@apply absolute min-h-9;
						}
					}
				}
			}

			& #myinfo {
				&-skin {
					&1 {
						&0 {
							& + span {
								@apply before:bg-[url('/images/mobile/myinfo/myinfo-skin10.png')];
							}
						}

						&1 {
							& + span {
								@apply before:bg-[url('/images/mobile/myinfo/myinfo-skin11.png')];
							}
						}

						&2 {
							& + span {
								@apply before:bg-[url('/images/mobile/myinfo/myinfo-skin12.png')];
							}
						}

						&3 {
							& + span {
								@apply before:bg-[url('/images/mobile/myinfo/myinfo-skin13.png')];
							}
						}
					}

					&2 {
						&0 {
							& + span {
								@apply before:bg-[url('/images/mobile/myinfo/myinfo-skin20.png')];
							}
						}

						&1 {
							& + span {
								@apply before:bg-[url('/images/mobile/myinfo/myinfo-skin21.png')];
							}
						}
					}

					&3 {
						&0 {
							& + span {
								@apply before:bg-[url('/images/mobile/myinfo/myinfo-skin30.png')];
							}
						}

						&1 {
							& + span {
								@apply before:bg-[url('/images/mobile/myinfo/myinfo-skin31.png')];
							}
						}

						&2 {
							& + span {
								@apply before:bg-[url('/images/mobile/myinfo/myinfo-skin32.png')];
							}
						}

						&3 {
							& + span {
								@apply before:bg-[url('/images/mobile/myinfo/myinfo-skin33.png')];
							}
						}

						&4 {
							& + span {
								@apply before:bg-[url('/images/mobile/myinfo/myinfo-skin34.png')];
							}
						}

						&5 {
							& + span {
								@apply before:bg-[url('/images/mobile/myinfo/myinfo-skin35.png')];
							}
						}
					}
				}
			}

			& .select {
				&-size {
					@apply bg-select bg-[length:0.75rem] bg-[right_0.5rem_top_50%] bg-no-repeat dark:bg-selectDark;

					&::-ms-expand {
						@apply hidden;
					}
				}
			}
		}

		& #layerpopup {
			&-googlemap {
				& .layerpopup {
					&-content {
						@apply !pb-4;
						padding-bottom: calc(theme("spacing.4") + env(safe-area-inset-bottom)) !important;
					}
				}
			}

			&-mobilemagazine {
				& .layerpopup {
					&-header {
						@apply border-0;
					}
				}
			}
		}

		& #tooltip {
			&-email {
				& > div {
					&:first-child {
						@apply border-b-0;
					}

					&:last-child {
						@apply pt-0;
					}
				}
			}
		}
	}
}

.app {
	#myinfo {
		& #layerpopup {
			&-googlemap {
				& .layerpopup {
					&-content {
						@apply !pb-12;
						padding-bottom: calc(theme("spacing.12") + env(safe-area-inset-bottom)) !important;
					}
				}
			}

			&-mobilemagazine {
				& .layerpopup {
					&-content {
						& > div {
							& > div {
								@apply pb-25;
								padding-bottom: calc(theme("spacing.25") + env(safe-area-inset-bottom));
							}
						}
					}
				}
			}

			&-newsletter {
				@apply pb-20;
			}
		}
	}
}
